import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import * as R from 'ramda'
import { Modal, Button } from 'shards-react'
import modalLogo from '../../assets/modal-logo.svg'
import './OtpModal.scss'
import services from '../../services'
import isNotYouEndPoint from '../../helpers/isNotYouEndpoint'

const OPT_LENGTH = 6


const OtpModal = ({
  open,
  toggle,
  channel,
  userToken,
  refNo,
  organizationId,
  handleSubmitRequestOtp,
}) => {
  const [userInputValue, setUserInputValue] = useState('')
  const [isComplete, setIsComplete] = useState(false)
  const [errorMessage, setErrorMassage] = useState(null)
  const [errorMsApplyForPrivilege, setErrorMsApplyForPrivilege] = useState(null)

  const channelMessage = () => (channel === 'email' ? ' อีเมลล์' : ' เบอร์โทรศัทพ์ของคุณ')
  const channelMessageCode = () => (channel === 'email' ? ' Email' : ' SMS')

  const renderRefNo = () => ` (Ref: ${refNo})`
  const submitApplyForPrivilege = async (token) => {
    try {
      await services.applyForPrivilege({ organizationId, token })
      setIsComplete(true)
    } catch (error) {
      const errorMs = R.pathOr(global.ERROR_MESSAGE, ['data', 'message'], error.response)
      setErrorMsApplyForPrivilege(errorMs)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const otp = userInputValue
    try {
      const result = await services.verifyOTP({ token: userToken, otp })
      const verifyOTP = R.pathOr(null, ['data', 'data', 'token'], result)
      await submitApplyForPrivilege(verifyOTP)
    } catch (error) {
      const errorMs = R.pathOr(global.ERROR_MESSAGE, ['data', 'message'], error.response)
      setErrorMassage(errorMs)
    }
  }

  const handleAutoFucusToInput = (e) => {
    document.getElementById('otp-input').focus()
  }

  const handleOnChange = (e) => {
    const { value } = e.target
    if (value.length <= OPT_LENGTH) { setUserInputValue(value) }
  }

  const renderUserInputElement = () => {
    const values = userInputValue ? userInputValue.split('') : []
    const renderElement = (index) => {
      if (index < values.length) {
        return (<div className="input-character">{values[index]}</div>)
      }
      return (<div className="input-character" />)
    }

    return (
      <div className="input-element">
        {renderElement(0)}
        {renderElement(1)}
        {renderElement(2)}
        {renderElement(3)}
        {renderElement(4)}
        {renderElement(5)}
      </div>
    )
  }

  if (isComplete) {
    return <Redirect to="/finale" />
  }
  if (errorMsApplyForPrivilege) {
    return (
      <Modal className="otp-modal" open={open} toggle={toggle}>
        <div className="error-section">
          <img className="logo" src={modalLogo} alt="modal-logo" />
          <div className="error-massage">
            {errorMsApplyForPrivilege}
          </div>
          <a className="btn btn-primary a-tag-button-style" href={isNotYouEndPoint(organizationId)}>กลับไปหน้าลงทะเบียน</a>
        </div>
      </Modal>
    )
  }
  return (
    <Modal className="otp-modal" open={open} toggle={toggle}>
      <div className="modal-container" onClick={handleAutoFucusToInput}>
        <img className="logo" src={modalLogo} alt="modal-logo" />
        <p>
          ระบบได้ส่งรหัส OTP ไปยัง
          {channelMessage()}
          ของคุณ กรุณาใส่รหัสที่ได้รับจาก
          {channelMessageCode()}
          <div>{renderRefNo()}</div>
        </p>
        <form className="otp-input" onSubmit={handleSubmit}>
          {renderUserInputElement(errorMessage)}
          <div className="wrong-otp-text">
            {errorMessage}
          </div>
          <Button type="submit" className="submit-bt">ตรวจสอบ</Button>
          <input
            id="otp-input"
            autoComplete="off"
            className="otp-input-hidden"
            type="number"
            onChange={handleOnChange}
          />
        </form>
        <button className="try-again-text" onClick={handleSubmitRequestOtp}>ขอรหัส OTP อีกครั้ง</button>
      </div>
    </Modal>
  )
}
export default OtpModal
