import React from 'react'
import './PrivacyPolicy.scss'

const PrivacyPolicy = () => (
  <div className="privacy-policy">
    <iframe
      className="privacy-policy-iframe"
      src="https://user-contents.ooca.co/statics/privacy-policies/th.html"
      title="privacy-policies"
    />
  </div>
)

export default PrivacyPolicy
