import React from 'react'
import ReactMarkdown from 'react-markdown'
import md from './TermsAndCondition.md'
import './TermsAndCondition.scss'

const TermsAndCondition = () => (
  <div className="terms-and-condition">
    <ReactMarkdown
      source={md}
    />
  </div>
)

export default TermsAndCondition
