import React from 'react'
import env from '../../config/env'

const App = () => {
  const callbackComposedUrl = `${env.CALLBACK_END_POINT}${window.location.search}`
  const redirectLocation = `${env.REDIRECT_ENDPOINT}/user/login?redirectUrl=${callbackComposedUrl}`

  return (
    <>
      {window.location = redirectLocation}
    </>
  )
}

export default App
