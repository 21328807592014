import styled from 'styled-components'
import PhoneInput from 'react-phone-number-input'

import 'react-phone-number-input/style.css'

export const InputPhoneCustomStyle = styled(PhoneInput)`
  &&& {
    input{
      border: none;
      box-shadow:none;
    }
  }
`