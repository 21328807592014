export default new Map([
  ['pwc', {
    organizationId: 10048,
    name: 'PwC Thailand',
    logo: '/logos/ooca.png',
    email_placeholder: 'xxxxx@pwc.com',
    landing_page_url: 'https://www.ooca.co/',
  }],
  ['kpmg', {
    organizationId: 10057,
    name: 'KPMG',
    logo: '/logos/kpmg.png',
    email_placeholder: 'Please provide your email address',
    landing_page_url: 'https://www.ooca.co/',
  }],
  ['kasikorn', {
    organizationId: 10041,
    name: 'KASIKORN',
    logo: '/logos/ooca.png',
    email_placeholder: 'Please provide your email address',
    landing_page_url: 'https://www.ooca.co/',
  }],
  ['salasod', {
    organizationId: 10018,
    name: 'TEST (Staging)',
    logo: '/logos/ooca.png',
    email_placeholder: 'Please provide your email address',
    landing_page_url: 'https://www.ooca.co/',
  }],
])