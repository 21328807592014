import React from 'react'
import { Link } from 'react-router-dom'
import oocaLogo from '../../assets/ooca.svg'
import './Navbar.scss'

const Navbar = () => (
  <div className="navbar">
    <Link className="navbar-logo" to="/">
      <img src={oocaLogo} alt="navbar-logo" />
    </Link>
  </div>
)
export default Navbar
