
const text = {
  consent: 'เพื่อยืนยันการตรวจสอบสิทธิ์การใช้งาน กรุณาตรวจสอบความถูกต้องของอีเมลโดยใช้อีเมลขององค์กรท่าน เท่านั้น และกรอกข้อมูลเบอร์โทร ก่อนกดยอมรับเงื่อนไขการใช้งาน',
  lernMoreAboutConsent: 'ท่านสามารถศึกษารายละเอียดเพิ่มเติมได้ที่',
  term: 'นโยบายความเป็นส่วนตัว',
  consentDescription: 'Ooca คำนึงถึงความเป็นส่วนตัวและความปลอดภัยของข้อมูลส่วนตัวของท่านอย่างสูงสุด ท่านสามารถศึกษารายละเอียดเพิ่มเติมได้ที่',
  accept: 'ยอมรับ',
  decline: 'ไม่ยอมรับ',
}

export default text
