import React from 'react'
import { Button } from 'shards-react'
import './Finale.scss'
import welcomeLogo from '../../assets/smile-icon.svg'
import env from '../../config/env'

const Finale = () => (
  <div className="finale">
    <img className="welcome-logo" src={welcomeLogo} alt="welcome-logo" />
    <h1>ยินดีต้อนรับเข้าสู่ OOCA</h1>
    <p>ตอนนี้คุณได้รับการดูแลจากผู้เชี่ยวชาญ ทางด้านสุขภาพจิตของเรา ตลอด 24 ชั่วโมง</p>
    <a className="submit-bt btn btn-primary" href={'https://ooca.page.link/get-started'}>มาเริ่มกันเลย</a>
  </div>
)

export default Finale
