
import axios from 'axios'
import Cookies from 'js-cookie'


const axiosMiddleware = (baseURL) => {
  const token = Cookies.get('token')
  return axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
    },
  })
}

export default axiosMiddleware
