import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import Cookies from 'js-cookie'
import * as R from 'ramda'
import jwt from 'jsonwebtoken'
import './PrivilegeApplyForm.scss'
import text from './PrivilegeApplyForm.text'
import env from '../../config/env'
import OtpModal from '../../components/OtpModal'
import services from '../../services'
import global from '../../config/global'
import isNotYouEndPoint from '../../helpers/isNotYouEndpoint'
import organizations from '../../config/oragnizations'
import { InputPhoneCustomStyle } from './style'

const PrivilegeApplyForm = () => {
  const [modal, setModal] = useState(false)
  const [errorMessage, setErrorMassage] = useState(null)
  const [userInputValue, setUserInputValue] = useState(null)
  const [userToken, setUserToken] = useState(null)
  const [refNo, setRefNo] = useState(null)

  const token = Cookies.get('token')
  const decodedToken = jwt.decode(token)

  const email = R.pathOr(null, ['data', 'email'], decodedToken)
  const telephone = R.pathOr(null, ['data', 'tel'], decodedToken)
  const userOtpChannel = email ? 'phone' : 'email'

  const urlParams = new URLSearchParams(window.location.search)
  const organizationId = parseInt(urlParams.get('organizationId'), 10)
  const organization = organizations.get(organizationId)

  console.log(`authInfo -> `, decodedToken);
  if(!decodedToken) {
    window.location.href = `${env.REDIRECT_ENDPOINT}/logout?redirectUrl=${encodeURIComponent(`${env.REDIRECT_ENDPOINT}/user/login?redirectUrl=${encodeURIComponent(`${env.CALLBACK_END_POINT}?organizationId=${organizationId}`)}`)}`;
    return <div>Redirecting ...</div>
  }

  const renderIsNotYou = () => (
    <a className="it-not-you-link" href={isNotYouEndPoint(organizationId)}>
      {email ? '(กดที่นี้หากไม่ใช่อีเมลบริษัทของท่าน)' : '(กดที่นี้หากไม่ใช่เบอร์โทรของท่าน)'}
    </a>
  )

  const renderInput = (telePhone) => {
    if (email) {
      return (
        <React.Fragment>
          <span className="apply-form-auto-fill">
            {email}
            {renderIsNotYou()}
          </span>
          <span className="input-name">กรุณากรอกเบอร์โทรศัพท์เพื่อรับสิทธิ์</span>

          <InputPhoneCustomStyle
            className="apply-form-input"
            placeholder="081xxxxxxx"
            value={userInputValue}
            onChange={setUserInputValue}
            defaultCountry="TH"
            limitMaxLength />
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        <span className="apply-form-auto-fill">
          {telePhone}
          {renderIsNotYou()}
        </span>
        <span className="input-name">กรุณากรอกอีเมลบริษัทเพื่อรับสิทธิ์</span>
        <input
          className="apply-form-input"
          onChange={(e) => setUserInputValue(e.target.value)}
          type="email"
          placeholder={organization.email_placeholder}
          required
        />
      </React.Fragment>
    )
  }


  const toggleModal = () => {
    setModal(!modal)
  }

  const handleSubmitRequestOtp = async (e) => {
    e.preventDefault()

    try {
      const result = await services.requestOTP({ [userOtpChannel]: userInputValue })
      setUserToken(R.pathOr(null, ['data', 'data', 'token'], result))
      setRefNo(R.pathOr(null, ['data', 'data', 'refNo'], result))
      setModal(true)
      setErrorMassage(null)
    } catch (err) {
      setErrorMassage(R.pathOr(global.ERROR_MESSAGE, ['data', 'message'], err.response))
    }
  }


  return (
    <div className="privilege-apply">
      <div className="apply-form">
        <img src={organization?.logo ?? ''} alt={organization.name} className="company-logo" />
        <span className="welcome"> ยินดีต้อนรับลูกค้าองค์กรทุกท่าน </span>
        <div className="apply-form-description consent">
          {text.consent}
        </div>
        <form className="apply-form" onSubmit={handleSubmitRequestOtp}>
          {renderInput(telephone)}
          <span className="error-message">{errorMessage}</span>
          <p className="apply-form-description">
            ข้าพเจ้าได้รับทราบและตกลงยอมรับ
            <Link to="/term-and-condition">
            ข้อตกลงและเงื่อนไขการใช้งานสำหรับผู้ใช้องค์กร
            </Link>
          </p>
          <div className="apply-bt-group">
            <a className="apply-form-bt decline" href={organization.landing_page_url}>{text.decline}</a>
            <button className="apply-form-bt primary" type="submit">{text.accept}</button>
          </div>
        </form>
        <span className="apply-form-description">
          {text.consentDescription}
          <Link to="/privacy-policy">
            {text.term}
          </Link>
        </span>
      </div>
      <OtpModal
        channel={userOtpChannel}
        open={modal}
        organizationId={organizationId}
        toggle={toggleModal}
        userToken={userToken}
        refNo={refNo}
        handleSubmitRequestOtp={handleSubmitRequestOtp}
      />
    </div>
  )
}

export default PrivilegeApplyForm
